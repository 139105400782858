<template>
    <div>
    <head>
      <title>Privacy Policy</title>
    </head>
    <div>
      <h1 class="doc">
        Privacy Policy
      </h1>
      At Raredex, we are dedicated to helping people participate in a global rare earth and technology metals community. We understand our responsibilities to protect personal information and have taken the necessary measures to gain the trust of our customers.

      <br>
      <br>
      We acquire, manage, and use personal information in an appropriate manner, taking into account the nature and scale of our business. We only handle personal information that is necessary to achieve the intended purpose, and we obtain prior consent from the relevant individual.

      <br>
      <br>
      We do not provide personal data to third parties unless we have obtained the consent of the relevant individual, or if there is a legal requirement to do so.
      <br>
      <br>

      We comply with all laws, regulations, national guidelines, and other norms related to the handling of personal information.
      <br>
      <br>

      We take the necessary measures and corrective actions to prevent the leakage, loss, or damage of personal information.
      <br>
      <br>
      Last revised date: 11/November/2024
      <br>
      <br>

      Handling of Personal Information
      <br>
      <br>

      We may handle your personal information in connection with transactions. We recognize the importance of protecting personal information and handle it appropriately in accordance with our Personal Information Protection Policy.
      <br>
      <br>

      Our basic stance on the handling of personal information is as follows:
      <br>
      <br>
      <br>

      We declare our Personal Information Protection Policy and ensure that our employees and related parties are fully aware of it.
      <br>
      <br>
      We acquire personal information by lawful and fair means and will not acquire it by illegal means.
      <br>
      <br>
      We inform individuals of the voluntary nature of providing personal information and the consequences of not doing so.
      <br>
      <br>
      We handle personal information for the following purposes:

      <br>
      <br>
      <br>
      To send direct mail or other relevant information, or to provide services on our Website, smart contracts, or social media accounts.
      <br>
      <br>
      To respond to enquiries about our operations.
      <br>
      <br>
      To provide guidance and information regarding our operations from us.
      <br>
      <br>
      To provide services to our customers.
      <br>
      <br>
      To advertise, promote or market our company.
      <br>
      <br>
      To take anti-fraud measures necessary for the safe operation of our website, etc.
      <br>
      <br>
      To provide information to a third party in the manner set forth in the Personal Information Protection Policy.
      <br>
      <br>
      If we use personal information for purposes other than those listed above, we will ask for consent unless otherwise permitted by law.

      <br>
      <br>
      Non-disclosure/Non-provision of Personal Data to Third Parties

      We do not disclose or provide your personal data to any third party without obtaining prior consent from you, except in the following cases:

      <br>
      <br>
      If it is necessary based on laws and regulations.
      <br>
      <br>
      If it is necessary for the protection of life, body, or property of any individual and it is difficult to obtain your consent.
      <br>
      <br>
      If it is particularly necessary for the improvement of public health or the promotion of the sound upbringing of children and it is difficult to obtain your consent.
      <br>
      <br>
      If it is necessary to cooperate with any governmental institution or local government or a person entrusted by them in the performance of affairs prescribed by the laws and regulations, and obtaining your consent may interfere with the performance of such affairs.
      <br>
      <br>

      Procedures for changing the Personal Information Protection Policy
      <br>
      <br>
      In order to handle personal information appropriately, we review and revise our Personal Information from time to time.
      <br>
      <br>

      <h2>Contact center for disclosure, correction/suspension of use and complaints</h2>

      With respect to your personal data held by us, you may request the notification, disclosure, suspension of use, elimination and suspension of provision to a third party of the purpose of use, disclosure, correction, addition, deletion, suspension of use and elimination of the Third Party Provision Records, as well as correction, addition, and deletion of the retained personal data (the Request for Disclosures). When making the Request for Disclosures, we may ask you to present your identification documents (including driver's license and passport) to confirm your identity.
      <br>
      However, if your request does not meet the requirements set forth under the applicable legal acts or if there are any grounds to refuse disclosures under these laws and regulations, we may not accept your request. In addition, it should be noted that our policy is to refuse to disclose information related to personnel evaluations regardless of whether such information falls under the category of personal information. 
      When we receive a request for notification of the purpose of use or a request for disclosure of the retained personal data held by our company, or a request for disclosure of the Third Party Provision Records, we will charge a fee of USD 6 per case.
      In addition, you can make inquiries about our handling of personal information and personal information protection management at info@raredex.io .
      Upon receiving an inquiry from you, the contact center will inform you of the procedures for the Request for Disclosures, and will respond to it appropriately and promptly to the reasonable extent.
      Note
      <h2>Contact Information for Personal Information Protection</h2>
      <ul>
        <li>
          privacy@raredex.io 
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Privacy",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>
