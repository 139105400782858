<template>
    <head>
      <title>Raredex Terms of Use</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
    <h1 class="doc">
      Raredex Terms of Use
    </h1><br>
    These terms of use (these &#x201CTerms&#x201D) relate to the use of facilities provided by &#x201CRaredex&#x201D (&#x201Cwe&#x201D or &#x201Cus&#x201D) on the Internet, (the &#x201CCommunity&#x201D, and together with related services,<br>hereinafter referred to as the &#x201CServices&#x201D) and set forth matters that Users (as defined in Article 2, Item (1) below) and prospective Users of the Services shall comply with as well as rights and obligations between Users and us.<br>In order to use the Services, you must read the full text of these Terms and agree to these Terms.<br>
    <br>
    <b>Article 1 (Definitions)</b><br>
    Terms used herein shall be defined as below:<br>
    (1) &#x201CUser&#x201D means a person who uses the Services;<br>
    (2) &#x201CUser ID&#x201D means an e-mail address that a User registers in order to use the Services;<br>
    (3) &#x201CPassword&#x201D means a unique character string set by a User associated with a User ID;<br>
    (4) &#x201CSmart Contracts&#x201D are self-executing contracts with the terms directly written into code on the blockchain.
    (5) &#x201CRegistration Information&#x201D is any information provided to us when starting use of Raredex Services.
    (6) &#x201CSocial Media User&#x201D means an identified that of a User that interacts with Raredex facilitated social media services;<br>
    (7) &#x201CTokens&#x201D refers to Smart Contract assets minted, transferred, or burnt by Raredex Smart Contracts.
    <br>
    <b>Article 2 (Scope of Application)</b><br>
    1. These Terms set forth the terms and conditions for the provision of the Services, and the rights and<br>
    obligations between Users and us in connection with the use of the Services, and shall apply to all<br>relationships between Users and us in connection with the use of the Services.<br>
    2. Rules on the use of the Services, policies and others (&#x201CUse Rules&#x201D) that we publish on the Company<br>
    Website shall constitute a part of these Terms.<br>
    3. In case of any inconsistency or discrepancy between these Terms and the Use Rules in the preceding<br>
    paragraph, the provisions of these Terms shall prevail unless otherwise specifically provided for in such<br>Use Rules.<br>
    <br>
    <b>Website & social media usage</b><br>
    <ul>
      <li>You must be at least 18 years old to use our services.</li>
      <li>You agree to provide accurate and complete information when creating an account.</li>
      <li>You are responsible for maintaining the confidentiality of your account credentials<br>
        (e.g. Password) or Social Media User credentials</li>
      <li>You agree to abide by any specific stipulations of social media Services when interacting with those Services.</li>
    </ul>
    <br>
    <b>Smart Contract Interactions</b><br>
    <ul>
      <li>You understand that interactions with our Smart Contracts are irreversible and immutable.</li>
      <li>You agree to use our Smart Contracts only for their intended purposes.</li>
      <li>Raredex is not responsible for any losses incurred due to user error or misunderstanding of Smart Contract functionality.</li>
    </ul>
    <br>
    <b>Article 3 (Management of Password and User ID)</b><br>
    1. Users shall properly manage and keep their Passwords and User IDs pertaining to the Services at their own<br>
    responsibility, and shall not allow a third party to use them, nor lend, assign, transfer or sell them to a third<br>party.<br>
    2. Users shall be fully liable for any damage caused by insufficient management, misuse, or use by a third<br>
    party of their Passwords or User IDs.<br>
    3. If any Registration Information is found to be stolen or used by a third party, the relevant User shall<br>
    immediately notify us thereof and follow our instructions. In such case, we may suspend the use of the<br>Services by such User ID and Password, and we shall not be liable for any damage incurred by such Users a result of such suspension of use.<br>
    4. We may treat any use of the Services as the use by the User of such account, and any and all consequences<br>
    arising from such use and any and all responsibility to us in connection therewith shall be attributed to such<br>User.<br>
    <br>
    <b>Article 4 (Management of Wallet)</b><br>
    In order to use the Services, Users shall use, in accordance with the manner separately specified by us, a<br>self-custody wallet (the &#x201CWallet&#x201D) provided by us or a third party other than us for which Users shall manage<br>the seed phrase and private key to transfer Tokens (the &#x201CPrivate Key, Etc.&#x201D) by themselves. The Wallet is for<br>Users to manage by themselves the Private Key, Etc. to transfer Tokens, and Users shall manage the Private<br>Key, Etc. at their own responsibility when using the Wallet, and we shall not be liable for any damages<br>incurred by Users due to leakage, loss of or damage to such Private Key, Etc.<br>
    <br>
    <b>Article 5 (Fees and Royalty)</b><br>
    We separately determine the fees payable by Users for using the Services.<br>
    <br>
    <b>Article 6 (Intellectual Property Rights, Etc.)</b><br>
    All content on the Website, including text, graphics, logos, and software, is the property of Raredex (unless otherwise stated) and protected by intellectual property laws. 
    <br>

    <b>Article 6. User Conduct</b><br>
    You agree not to:<br>
    <ul>
      <li>Use the Website or Smart Contracts for any illegal purposes</li>
      <li>Attempt to interfere with the proper functioning of the Website or Smart Contracts.</li>
      <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
    </ul>
    <br>
    <b>Article 7. Smart Contract Execution</b><br>
    <ul>
      <li>Smart Contracts will automatically execute when predetermined conditions are met.</li>
      <li>You acknowledge that Raredex cannot reverse or alter the execution of Smart Contracts once initiated.</li>
      <li>You are responsible for understanding the terms and conditions encoded in the Smart Contracts before interacting with them.</li>
    </ul>
    <br>
    <b>Article 8. Liability Limitations</b><br>
    <ul>
      <li>Raredex is not liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Website or Smart Contracts.</li>
      <li>We do not guarantee the accuracy, completeness, or usefulness of any information on the Website or in the Smart Contracts.</li>
    </ul>
    <br>

    <br><b>Article 9. Privacy</b><br>
    Your use of our services is also governed by our Privacy Policy, which is incorporated into these Terms of Use by reference.<br>

    <br><b>Article 10. Modifications to Services</b><br>
    Raredex reserves the right to modify or discontinue any part of our services without notice.<br>
    We are not liable to you or any third party for any modification, suspension, or discontinuance of our services.<br>

    <br><b>Article 11. Termination</b><br>
    We reserve the right to terminate or suspend your account and access to our services at our sole<br>
    discretion, without notice, for conduct that we believe violates these Terms of Use or is harmful to<br>
    other users, us, or third parties, or for any other reason.<br>

    <br><b>Article 12. Governing Law</b><br>
    These Terms of Use shall be governed by and construed in accordance with the laws of our jurisdiction of incorporation, without regard to its conflict of law provisions.<br>

    <br><b>Article 13. Changes to Terms</b><br>
    We reserve the right to update or modify these Terms of Use at any time without prior notice.<br>
    Your continued use of our services following any changes indicates your acceptance of the new Terms of Use.<br> 

    <br><b>Article 14. Contact Information</b><br>
    If you have any questions about these Terms of Use, please contact us at legal@raredex.io.<br>
    By using our Website or interacting with our Smart Contracts, you acknowledge that you have<br>
    read, understood, and agree to be bound by these Terms of Use.<br><br>

    Last updated: November 4, 2024
    <br>
    <br>
    <br>
    <hr>
  </div>
</template>
<script>

import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Terms",
    "components": {
    },
    "props": {
    },
    setup () {

        const serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  margin-top: 100px;
  margin-bottom: 100px;
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>

