<template>
  <div ref="rootNode">
    <div ref="graphContainer" style="position: relative">
      <div id="graphtip" :style="`background: ${graphTipColor}; color: ${graphTipBWColor};`">
        <div v-html="graphTipContent" />
        <div class="tool-line" :style="`border: 1px solid ${graphTipColor}`">
          <div class="tool-dot" :style="`border: 5px solid ${graphTipColor}`" />
        </div>
      </div>
    </div>
    <div v-if="hideControls == false" class="controls-container">
      <div class="time-range-container">
        <div
          class="time-range"
          @click="setTimeRange('oneHour')"
        >
          1H
        </div>
        <div
          class="time-range"
          @click="setTimeRange('oneDay')"
        >
          1D
        </div>
        <div
          class="time-range"
          @click="setTimeRange('oneWeek')"
        >
          1W
        </div>
        <div
          class="time-range"
          @click="setTimeRange('oneMonth')"
        >
          1M
        </div>
        <div
          class="time-range"
          @click="setTimeRange('threeMonth')"
        >
          3M
        </div>
        <div
          class="time-range"
          @click="setTimeRange('sixMonth')"
        >
          6M
        </div>
        <div
          class="time-range"
          @click="setTimeRange('oneYear')"
        >
          1Y
        </div>
        <div
          class="time-range"
          @click="setTimeRange('fiveYear')"
        >
          5Y
        </div>
        <div
          class="time-range"
          @click="setTimeRange('tenYear')"
        >
          10Y
        </div>
        <div
          class="time-range"
          @click="setTimeRange('ytd')"
        >
          YTD
        </div>
        <div
          class="time-range"
          @click="setTimeRange('all')"
        >
          All
        </div>
      </div>
      <div class="time-scale-container">
        <div
          class="time-scale"
          @click="setTimeScale(null)"
        >
          Default
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('1min')"
        >
          1min
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('15min')"
        >
          15min
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('30min')"
        >
          30min
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('hourly')"
        >
          hourly
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('daily')"
        >
          daily
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('weekly')"
        >
          weekly
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('monthly')"
        >
          monthly
        </div>
        <div
          class="time-scale"
          @click="setTimeScale('yearly')"
        >
          yearly
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {emit, inject, onMounted, getCurrentInstance, ref, toRefs, toRef, watch} from "vue";
import * as d3 from "d3";
import {translationStore} from "@/stores/translation";
import Web3 from "web3";

export default {
    "name": "D3",
    "components": {
    },
    "props": [
        "data",
        "symbol",
        "tokens",
        "timeFrame",
        "strokeWidth",
        "xTicksIn",
        "yTicksIn",
        "hideXAxis",
        "hideYAxis",
        "hideControls",
        "axisColor",
        "multiGraph"
    ],
    "emits": [
        "passback-symbols"
    ],
    setup (props, {emit}) {

        const provider = ref(),

            serverConfig = toRefs(inject("serverConfig")),
            translation = translationStore(),
            rootNode = ref(),
            symbols = ref({}),
            axisColor = toRef(props, 'axisColor'),
            timeFrame = toRef(props, 'timeFrame'),
            strokeWidth = toRef(props, 'strokeWidth'),
            hideControls = toRef(props, 'hideControls'),
            xTicksIn = toRef(props, 'xTicksIn'),
            yTicksIn = toRef(props, 'yTicksIn'),
            hideXAxis = toRef(props, 'hideXAxis'),
            hideYAxis = toRef(props, 'hideYAxis'),
            multiGraph = toRef(props, 'multiGraph'),
            graphContainer = ref(null),
            graphTip = ref(),
            graphTipContent = ref('<div />'),
            graphData = toRef(props, 'data'),
            graphTipColor = ref(),
            graphTipBWColor = ref(),
            selectedSymbol = toRef(props, 'symbol'),
            tokenData = toRef(props, 'tokens'),
            marginTop = ref(20),
            marginRight = ref(20),
            marginBottom = ref(30),
            marginLeft = ref(80),
            xAxis = ref(),
            yAxis = ref(),
            x = ref(),
            y = ref(),
            canvas = ref(),
            startDate = ref(new Date()),
            endDate = ref(new Date()),
            startScale = ref(0),
            endScale = ref(100),
            ticks = ref(10),
            timeScale = ref('yearly'),
            height = ref(),
            width = ref(),
            initGraph = function () {

                canvas.value = d3.select(graphContainer.value).append('svg');

                width.value = rootNode.value.parentElement.clientWidth;
                height.value = rootNode.value.parentElement.clientHeight;

                x.value = d3.scaleUtc()
                  .domain([startDate.value, endDate.value])
                  .range([marginLeft.value, width.value - marginRight.value]);

                y.value = d3.scaleLinear()
                  .domain([startScale.value, endScale.value])
                  .range([height.value - marginBottom.value, marginTop.value]);

                if (xTicksIn.value) {

                    ticks.value = xTicksIn.value;

                } else {

                    ticks.value = parseInt(width.value / 100);

                }

                xAxis.value = d3.axisBottom(x.value).ticks(ticks.value);

                if (yTicksIn.value) {

                    yAxis.value = d3.axisLeft(y.value).ticks(yTicksIn.value);

                } else {

                    yAxis.value = d3.axisLeft(y.value).ticks(10);

                }

                canvas.value
                  .attr("width", width.value)
                  .attr("height", height.value)
                  .attr('viewBox', `0 0 ${width.value} ${height.value}`)
                  .attr('preserveAspectRatio','xMidYMid meet');

                canvas.value.append("g")
                  .attr('class', 'x-axis')
                  .attr("transform", `translate(0,${height.value - marginBottom.value})`)
                  .call(xAxis.value);

                canvas.value.append("g")
                  .attr('class', 'y-axis')
                  .attr("transform", `translate(${marginLeft.value},0)`)
                  .call(yAxis.value);

            },
            addGraph = function() {

                // add a path
                canvas.value 
                  .append("path")
                    .on("mouseover", mouseOverPath)
                    .transition()
                    .duration(100)
                    .attr("class", selectedSymbol.value)
                    .style('transition-duration', 100)
                    .style('transition', 'all');

            },
            loadGraph = async function () {

                var low, high;

                for (var key in graphData.value) {

                    let data_ = [];

                    for (var i in graphData.value[key].usd) {

                        if ((!low || (parseFloat(graphData.value[key].usd[i]) < low)) 
                            && (symbols.value[key] === true)) {

                            low = parseFloat(graphData.value[key].usd[i]);

                        }

                        if ((!high || (parseFloat(graphData.value[key].usd[i]) > high))
                            && (symbols.value[key] === true)) {

                            high = parseFloat(graphData.value[key].usd[i]);

                        }

                        let date = new Date(i);

                        if (date > startDate.value && date < endDate.value) {

                            data_.push({

                                date,
                                value: graphData.value[key].usd[i]

                            });

                        }

                    }

                    const line = d3
                      .line()
                      .x((d) => { return x.value(d.date); })
                      .y((d) => { return y.value(d.value); })
                      //.curve(d3.curveCardinal);
                      .curve(d3.curveBasis);

                    if (tokenData.value[key].strokeWidth) {

                        strokeWidth.value = tokenData.value[key].strokeWidth;

                    }

                    if (!strokeWidth.value) {

                        strokeWidth.value = 2;

                    }

                    canvas.value
                      .select('.' + key)
                      .datum(data_)
                      .transition()
                      .duration(300)
                      .style('transition-duration', 300)
                      .style('transition', 'all')
                      .style('opacity', tokenData.value[key].opacity)
                      .attr("fill", "none")
                      .attr("stroke", tokenData.value[key].color)
                      .attr("stroke-linejoin", "round")
                      .attr("stroke-linecap", "round")
                      .attr("stroke-width", strokeWidth.value)
                      .attr("d", line);

                }

                startScale.value = low*0.8;
                endScale.value = high*1.2;

            },
            renderGraph = function () {

                clearGraphTip();

                const graph = document.getElementById("graph-container");

                width.value = rootNode.value.clientWidth;
                height.value = rootNode.value.clientHeight;

                if (xTicksIn.value) {

                    ticks.value = xTicksIn.value;

                } else {

                    ticks.value = parseInt(width.value / 100);

                }

                let timeDifference = endDate.value.getTime() - startDate.value;
                var customTick = timeDifference / ticks.value;

                switch (timeScale.value) {

                    case '1min':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%H %M'));
                      break;
                    case '15min':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%H %M'));
                      break;
                    case '30min':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%H %M'));
                      break;
                    case 'hourly':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%d %H'));
                      break;
                    case 'daily':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%d %B'));
                      break;
                    case 'weekly':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%B %Y'));
                      break;
                    case 'monthly':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%B %Y'));
                      break;
                    case 'yearly':
                      xAxis.value = d3.axisBottom(x.value).tickValues(d3.range(startDate.value, endDate.value, customTick).map(function(d){ return new Date(d) })).tickFormat(d3.timeFormat('%B %Y'));
                      break;
                    default:
                      xAxis.value = d3.axisBottom(x.value).ticks(ticks.value);

                }

                x.value
                  .domain([startDate.value, endDate.value])
                  .range([marginLeft.value, width.value - marginRight.value]);

                canvas.value.select('.x-axis')
                  .transition()
                  .duration(100)
                  .attr("transform", `translate(0,${height.value - marginBottom.value})`)
                  .call(xAxis.value);

                y.value
                  .domain([startScale.value, endScale.value])
                  .range([height.value - marginBottom.value, marginTop.value]);

                canvas.value.select('.y-axis')
                  .transition()
                  .duration(100)
                  .attr("transform", `translate(${marginLeft.value},0)`)
                  .call(yAxis.value);

                canvas.value
                  .transition()
                  .duration(100)
                  .attr('width', width.value)
                  .attr('height', height.value)
                  .attr('viewBox', `0 0 ${width.value} ${height.value}`);

            },
            setTimeScale = function (v) {

                timeScale.value = v;

                let timeDifference = endDate.value.getTime() - startDate.value;

                xAxis.value = d3.axisBottom(x.value).ticks(ticks.value);

                loadGraph();
                renderGraph();

            },
            setTimeRange = function (v) {

                const now = new Date();
                const start = new Date(now);

                switch (v) {

                    case 'oneHour':
                      startDate.value = start.setHours(now.getHours() - 1);
                      endDate.value = now;
                      break;
                    case 'oneDay':
                      startDate.value = start.setHours(now.getHours() - 24);
                      endDate.value = now;
                      break;
                    case 'oneWeek':
                      startDate.value = start.setDate(now.getDate() - 7);
                      endDate.value = now;
                      break;
                    case 'oneMonth':
                      startDate.value = start.setMonth(now.getMonth() - 1);
                      endDate.value = now;
                      break;
                    case 'threeMonth':
                      startDate.value = start.setMonth(now.getMonth() - 3);
                      endDate.value = now;
                      break;
                    case 'sixMonth':
                      startDate.value = start.setMonth(now.getMonth() - 6);
                      endDate.value = now;
                      break;
                    case 'oneYear':
                      startDate.value = start.setFullYear(now.getFullYear() - 1);
                      endDate.value = now;
                      break;
                    case 'fiveYear':
                      startDate.value = start.setFullYear(now.getFullYear() - 5);
                      endDate.value = now;
                      break;
                    case 'tenYear':
                      startDate.value = start.setFullYear(now.getFullYear() - 10);
                      endDate.value = now;
                      break;
                    case 'ytd':
                      startDate.value = start.setFullYear(now.getFullYear(), 0, 1);
                      endDate.value = now;
                      break;
                    case 'all':
                      break;
                    default:
                      startDate.value = start.setFullYear(now.getFullYear(), 0, 1);
                      endDate.value = now;

                }

                renderGraph();
                loadGraph();

            },
            hideDomainAxis = function (d) {

                if (d === 'x') {

                  canvas.value.select('.x-axis')
                      .attr('stroke-width', 0);

                }

                if (d === 'y') {

                  canvas.value.select('.y-axis')
                      .attr('stroke-width', 0);

                }

            },
            clearGraphTip = function () {

                const tt = document.getElementById("graphtip");
                tt.style.opacity = 0;

            },
            // ripped from https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
            blackOrWhite = function (hex) {

                if (hex.indexOf('#') === 0) {
                    hex = hex.slice(1);
                } else {
                    return "#000"
                }
                if (hex.length === 3) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                if (hex.length !== 6) {
                    throw new Error('Invalid HEX color.');
                }

                var r = parseInt(hex.slice(0, 2), 16),
                    g = parseInt(hex.slice(2, 4), 16),
                    b = parseInt(hex.slice(4, 6), 16);

                return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                    ? '#000000'
                    : '#FFFFFF';

            },
            mouseOverPath = function (e) {

                const [mouseX, mouseY] = d3.pointer(e);
                const xValue = x.value.invert(mouseX);
                const yValue = y.value.invert(mouseY).toFixed(2);

                let key = e.target.classList[0];

                if (symbols.value[key] === false || tokenData.value[key].opacity == 0) {

                    return;

                }

                const tt = document.getElementById("graphtip");
                tt.style.opacity = "1";
                tt.style.transform = `translate(${e.offsetX}px, ${(e.offsetY-112)}px)`;

                graphTipContent.value = `<div class="flex-col"><div>${key} : ${yValue}</div><div> ${xValue.toUTCString()}</div></div>`;

                if (tokenData.value[key]) {

                    graphTipColor.value = tokenData.value[key].color;
                    graphTipBWColor.value = blackOrWhite(tokenData.value[key].color);
                    tt.style.border = `2px solid ${ tokenData.value[key].color }`;

                } else {

                    graphTipColor.value = "#fff"
                    tt.style.border = "2px solid white";

                }

            };

        onMounted(async () => {

            initGraph();

            if (!hideControls.value) {

                hideControls.value = false;

            }

            if (!axisColor.value) {

                axisColor.value = "#666";

            }

            if (hideXAxis.value == "true") {

                hideDomainAxis('x');

            }

            if (hideYAxis.value == "true") {

                hideDomainAxis('y');

            }

            // set an initial time (impotant , without this y-axis does not display).
            setTimeout(
                () => {

                startScale.value = 10*0.8;
                endScale.value = 100*1.2;

                    renderGraph();
                    setTimeRange(timeFrame.value);

                },
                100
            );

            watch(
                selectedSymbol,
                () => {

                    if (selectedSymbol.value === 'react') {

                        console.log('react return')
                        return;

                    }

                    if (multiGraph.value == "true") {

                      if (symbols.value[selectedSymbol.value] === false) {

                          tokenData.value[selectedSymbol.value].opacity = 1;
                          symbols.value[selectedSymbol.value] = true;

                      } else if (symbols.value[selectedSymbol.value] === true) {

                          tokenData.value[selectedSymbol.value].opacity = 0;
                          symbols.value[selectedSymbol.value] = false;

                      } else {

                          tokenData.value[selectedSymbol.value].opacity = 1;
                          addGraph(selectedSymbol.value);
                          symbols.value[selectedSymbol.value] = true;

                      }

                    } else {

                      for (var i in symbols.value) {

                          symbols.value[i] = false;
                          tokenData.value[i].opacity = 0;

                      }

                      if (!symbols.value[selectedSymbol.value]) {

                          addGraph(selectedSymbol.value);

                      }

                      tokenData.value[selectedSymbol.value].opacity = 1;
                      symbols.value[selectedSymbol.value] = true;

                    }

                    // pass back to parent (how retarted are vue3 dev team?)
                    emit(
                        "passback-symbols",
                        symbols.value
                    );

                    loadGraph();
                    renderGraph();

                    setTimeout(
                        () => {

                            loadGraph();

                        },
                        100
                    );

                }

            );

            watch(
                serverConfig.chainId,
                () => {

                    //changeChain(serverConfig.chainId.value);

                }
            );

            loadGraph();

            watch(
                serverConfig.windowWidth,
                () => {

                    loadGraph();
                    renderGraph();

                }
            );

        });

        return {"localize": translation.localize,
            serverConfig,
            rootNode,
            timeFrame,
            symbols,
            tokenData,
            clearGraphTip,
            strokeWidth,
            graphTip,
            graphTipColor,
            graphTipBWColor,
            graphTipContent,
            graphData,
            graphContainer,
            ticks,
            xTicksIn,
            yTicksIn,
            hideXAxis,
            hideYAxis,
            hideDomainAxis,
            hideControls,
            multiGraph,
            axisColor,
            width,
            height,
            marginTop,
            marginRight,
            marginBottom,
            marginLeft,
            xAxis,
            x,
            y,
            canvas,
            startDate,
            endDate,
            startScale,
            endScale,
            initGraph,
            addGraph,
            renderGraph,
            timeScale,
            setTimeScale,
            setTimeRange,
            mouseOverPath,
            blackOrWhite,
            translation};

    }
};

</script>
<style scoped>
#graph-container {
    max-width: 100%;
    margin: 0 auto;
}
.controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  flex-wrap: wrap;
}
.time-scale {
  display: flex;
  flex-direction: row;
  margin: 2px;
  padding: 5px;
  border-bottom: 1px solid v-bind(axisColor);
  border-radius: 2px;
  color: v-bind(axisColor);
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}
.time-scale:hover {
  border-bottom: 1px solid v-bind(axisColor);
  cursor: pointer;
}
.time-range {
  display: flex;
  flex-direction: row;
  margin: 2px;
  padding: 5px;
  border-bottom: 1px solid v-bind(axisColor);
  border-radius: 2px;
  color: v-bind(axisColor);
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}
.time-range:hover {
  border-bottom: 1px solid v-bind(axisColor);
  cursor: pointer;
}
.time-range-container {
  display: flex;
  flex-direction: row;
}
.time-scale-container {
  display: flex;
  flex-direction: row;
}
</style>
<style>
.x-axis {
  color: v-bind(axisColor);
  font-weight: bold;
  font-size: 11px;
  text-shadow: 0px 0px 10px v-bind(#fff);
}
.y-axis {
  width: 100px;
  color: v-bind(axisColor);
  font-weight: bold;
  font-size: 11px;
  text-shadow: 0px 0px 10px v-bind(#fff);
}
.tick {
  width: 100px;
}
#graphtip {
  position: absolute;
  top: 0;
  left: 0px;
  width: auto;
  height: 35px;
  padding: 15px;
  margin-left: -20px;
  border-radius: 15px;
  opacity: 0;
  color: white;
  background: white;
  transition: all 300ms ease-in-out;
  font-size: 0.8em;
  font-weight: bold;
}
.tool-line {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 0px;
  height: 40px;
  margin: 14px 0 0 0px;
  border: 1px solid;
}
.tool-dot {
  position: absolute;
  border-radius: 50%;
  left: -5px;
  top: 36px;
}
</style>
