<template>
  <div
    class="modal-dialog"
  >
    <LocalizedContent ref="lc" />
    <div class="flex-col">
      <div class="super-line">
        <div class="super-text">
          <b> {{ localize("TEXT_PAY_WITH") }} </b>
        </div>
        <div
          class="super-text"
        >
          {{ localize("TEXT_BALANCE") }} : {{ sellTokens[sellToken].balance }}
        </div>
      </div>
      <div class="pay-out">
        <div
          class="token-dropdown"
          @click="openTokensModal('sell')"
        >
          <div class="swap-token-container margin-sell">
            <!-- Is token-img ever used, given that the icon is there?-->
            <!--<img
              class="token-img"
              :src="sellTokens[sellToken].icon"
            > -->
            <div
              class="token-icon"
              v-html="getRawIcon(sellTokens[sellToken].icon)"
            />
            <div class="symbol-text">
              {{ sellTokens[sellToken].symbol }}
            </div>
            <div
              class="token-icon"
              v-html="getRawIcon('EXPAND_ARROW_DOWN_LIGHT')"
            />
          </div>
        </div>
        <div class="pay-amount">
          <div>
<!--
              @keydown="formatSellAmount()"
-->
            <input
              placeholder="0.00"
              @input="event => sellAmount = event.target.value"
              type="number"
              step="0.1"
              class="pay-amount-input" />
          </div>
<!--
          <div class="pay-in-amount-subscript">
            ${{ sellAmount * (sellTokens[sellToken].price/100).toFixed(2) }}
          </div>
-->
        </div>
      </div>
      <div class="super-line">
        <div class="super-text">
          <b> {{ localize("TEXT_RECEIVE") }} </b>
        </div>
        <div
          v-if="isMetal(buyToken)"
          class="super-text">
          {{ localize("TEXT_BALANCE") }} : {{ (buyTokens[buyToken].balance/1000).toFixed(3) }} kg.
        </div>
      </div>
      <div class="pay-in-outer">
        <div class="pay-in-inner">
          <div
            v-if="buyTokens[buyToken]"
            class="token-dropdown"
            @click="openTokensModal('buy')"
          >
            <div class="swap-token-container margin-buy">
              <div v-if="isUrl(buyTokens[buyToken].icon) === true">
                <img
                  class="token-img"
                  :src="buyTokens[buyToken].icon"
                >
              </div>
              <div v-else>
                <div
                  class="token-icon"
                  v-html="getRawIcon(buyTokens[buyToken].icon)"
                />
              </div>
              <div class="symbol-text">
                {{ buyTokens[buyToken].symbol }}
              </div>
              <div
                class="token-icon"
                v-html="getRawIcon('EXPAND_ARROW_DOWN_LIGHT')"
              />
            </div>
          </div>
          <div
            class="pay-amount"
            v-if="buyTokens[buyToken]"
          >
            <div class="pay-amount-input" >
              {{ (receiveAmount / 100).toFixed(2) }}
            </div>
            <div class="pay-out-amount-subscript">
              ${{ (receiveAmount * buyTokens[buyToken].price/100000).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="flex-row">
      <div>
      </div>
    </div>
    <div
      class="swap-text-box"
      @click=placeOrder
    >
      <span><span class="swap-text">Swap</span></span>
    </div>
    <div>
      <span
        id="message"
        class="success"
      >
        {{ message }}
      </span>
    </div>

    <Transform>
      <CModal
        v-if="showTokensModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showTokensModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_CHOOSE_TOKEN") }} </div>
        </template>
        <template #body>
          <div
            v-if="buyOrSell == 'buy'"
            class="token-modal-container">
            <div
              v-for="(t,i) in buyTokens"
              :key="i"
              @click="changeToken(t.symbol)"
            >
              <div class="swap-token-container margin-sell">
                <div class="flex-row">
                  <div v-if="isUrl(t.icon) === true">
                    <img
                      class="token-img"
                      :src="t.icon"
                    >
                  </div>
                  <div v-else>
                    <div
                      class="token-icon"
                      v-html="getRawIcon(t.icon)"
                    />
                  </div>
                  <div class="flex-col">
                    <div class="symbol-text">
                      {{ t.symbol }}
                    </div>
                    <div class="subscript">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
                <div class="token-pricing">
                  <div class="flex-col">
                    <div class="balance-text">
                      {{ (t.balance/1000).toFixed(3) }} kg.
                    </div>
                    <div class="subscript">
                      ${{ (t.balance/1000 * t.price/100).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="buyOrSell == 'sell'"
            class="token-modal-container"
          >
            <div
              v-for="(t,i) in sellTokens"
              :key="i"
              @click="changeToken(t.symbol)"
            >
              <div
                v-if="t.address"
                class="swap-token-container margin-sell"
              >
                <div class="flex-row">
                  <div v-if="isUrl(t.icon) === true">
                    <img
                      class="token-img"
                      :src="t.icon"
                    >
                  </div>
                  <div v-else>
                    <div
                      class="token-icon"
                      v-html="getRawIcon(t.icon)"
                    />
                  </div>
                  <div class="flex-col">
                    <div class="symbol-text">
                      {{ t.symbol }}
                    </div>
                    <div class="subscript">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
                <div class="token-pricing">
                  <div class="flex-col">
                    <div class="balance-text">
                      {{ t.balance }}
                    </div>
                    <div class="subscript">
                      ${{ (t.balance/1000 * t.price/100).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>

    <Transform>
      <CModal
        v-if="showSuccessModal"
        width="small"
        color="#efefef"
        backgroundColor="#333"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showSuccessModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_SUCCESS") }} </div>
        </template>
        <template #body>
          <div>
            <div
              class="success"
              v-html="getRawIcon('CIRCLE_CHECK')"
            />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {emit, inject, onMounted, ref, toRef, toRaw, toRefs, watch} from "vue";
import {translationStore} from "@/stores/translation";
import Axios from "axios";
import {erc20ABI,
  erc20FactoryABI,
  rwaTokenABI,
  rwaTokenFactoryABI,
  iRwaMintableABI,
  iRwaTokenABI,
  raredexMintABI} from "../abi.js";

export default {
    "name": "Buy",
    "components": {
    },
    "props": {
        "buyToken": {
            type: String,
            default: ""
        },
        "buyTokens": {
            type: Array,
            default: []
        },
        "sellTokens":{
            type: Object,
            default: {}
        },
        "sellToken":{
            type: Object,
            default: {}
        },
        "sellAmount":{
            type: Number,
            default: 0
        }
    },
    "emits": [
        "hide-modal-event",
        "get-orders"
    ],
    setup (props, {emit}) {

        const web3 = inject("web3"),
            serverConfig = toRefs(inject("serverConfig")),
            icon = iconStore(),
            translation = translationStore(),
            showTokensModal = ref(false),
            showSuccessModal = ref(false),
            wallet = ref(),
            token = ref(),
            buyOrSell = ref(),
            buyToken = toRef(props, 'buyToken'),
            buyTokens = toRef(props, 'buyTokens'),
            sellToken = toRef(props, 'sellToken'),
            sellTokens = toRef(props, 'sellTokens'),
            sellAmount = toRef(props, 'sellAmount'),
            currency = ref('USD'),
            receiveAmount = ref(0),
            message = ref(),
            isOnChain = ref(false),
            onOffChain = ref('Off Chain'),
            isFiat = function (s) {

                return ["USD", "EUR"].includes(s);

            },
            isMetal = function (s) {

                return ["RDY", "RND", "RPR", "RTB", "RGA", "RGE", "RHF", "RIN", "RRE"].includes(s);

            },
            formatSellAmount = function () {

                let v = sellAmount.value;

                if (!v) {

                    v = '0';

                }

                v = v.replace(/^0+/, '');

                if (sellToken.value === "USD" || sellToken.value === "EUR") {

                    if (!v) {

                        return 0.00;

                    }

                    v = v.replace('.','');

                    if (v.length == 1) {

                        return '0.0' + v;

                    } else if (v.length == 2) {

                        return '0.' + v;

                    } else {

                        console.log(v)
                        return v.substring(0,v.length-2) + "." + v.substring(v.length-2);
        
                    }

                }

                return v;

            },
            placeOrder = function (v) {

                const amount = sellAmount.value;

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}placeOrder`,
                    {
                        "params": {

                            sellToken: sellToken.value,
                            buyToken: buyToken.value,
                            sellAmount: amount,
                            buyPrice: buyTokens.value[buyToken.value].price,
                            orderType: 'market',
                            currency: currency.value

                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {

                            emitHideModal();
                            emit(
                                "get-orders",
                                false
                            );
                            showSuccessModal.value = true;
                            setTimeout(
                                () => {

                                    showSuccessModal.value = false;

                                },
                                3000
                            );

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getSwap = async function() {

                receiveAmount.value = 1;

                const mintToken = toRaw(buyTokens.value[buyToken.value])?.address;
                const swapToken = toRaw(sellTokens.value[sellToken.value])?.address;

                if (web3.value.utils.isAddress(process.env.VUE_APP_ARBITRUM_MINTER_ADDRESS) === true &&
                    web3.value.utils.isAddress(swapToken) === true &&
                    web3.value.utils.isAddress(mintToken) === true) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],
                        amc = new web3.value.eth.Contract(
                            raredexMintABI,
                            process.env.VUE_APP_ARBITRUM_MINTER_ADDRESS
                        );

                    amc.methods.mintAmount(
                      mintToken,
                      swapToken,
                      sellAmount.value).call()
                      .then((mintAmount) => {
                        console.log("Mint Amount:", mintAmount);
                    });

                    return;

                    let gasLimit = await amc.methods.mintAmount(
                            mintToken,
                            swapToken,
                            sellAmount.value
                        ).estimateGas({"from": addr,
                            "gas": 5000});

                    gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                    let gasPrice = await web3.value.eth.getGasPrice();

                    gasPrice = BigInt(gasPrice) * 12n / 10n;
                    gasPrice = web3.value.utils.toHex(Number(gasPrice));

                    amc.methods.mintAmount(
                            mintToken,
                            swapToken,
                            sellAmount.value
                    ).send({
                        "from": addr,
                        gasPrice,
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");
                            console.log(jsonRpcResult);
                            receiveAmount.value = 0;

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
/*
            getSwap = function() {

                const amount = sellAmount.value;

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getSwap`,
                    {
                        "params": {

                            sellToken: sellToken.value,
                            buyToken: buyToken.value,
                            amount,
                            currency: currency.value

                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {

                            receiveAmount.value = response.data.swap_price;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
*/
            openTokensModal = function(bos) {

                buyOrSell.value = bos;
                showTokensModal.value = true

            },
            changeToken = function (t) {

                if (buyOrSell.value === 'sell') {

                    sellToken.value = t;

                }
                
                if (buyOrSell.value === 'buy') {

                    buyToken.value = t;

                }

                showTokensModal.value = false;

            },
            setOnOffChain = function (e) {

                isOnChain.value = e;

                if (e === false) {

                    onOffChain.value = "Off Chain";

                } else if (e === true) {

                    onOffChain.value = "On Chain";

                }

            },
            emitHideModal = () => {

                emit(
                    "hide-modal-event",
                    false
                );

            },
            getAllTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getAllTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getMyTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getMyTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        loading.value = false;

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getAccount = async function () {

                try {

                    const accounts = await web3.value.eth.getAccounts();

                    if (accounts) {

                        return accounts[0];

                    } else {

                        gotoPath('login/');

                    }

                } catch (e) {
                }

            },
            getTokenBalance = async function (token) {

                const tokenContract = new web3.value.eth.Contract(erc20ABI, token);

                try {

                    const balanceWei = await tokenContract.methods.balanceOf(wallet.value).call();
                    const balanceUsd = parseInt(balanceWei) / (10**6);
                    return balanceUsd;

                } catch (error) {

                    console.log(error)

                }

            },
            swap = function () {

                if (loginClass.value == "send-inactive") {

                    validatePassword();
                    return;

                }
                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}login`,
                    {
                        "email": email.value,
                        "password": password.value
                    }
                ).
                    then((response) => {

                        if (response.data) {

                            if (response.data.authorized == true) {

                                document.getElementById("message").className = "success";
                                setTimeout(
                                    () => {

                                        close();

                                    },
                                    500
                                );

                            } else {

                                document.getElementById("message").className = "error";
                                message.value = translation.localize("LOGIN_FAILED");

                            }

                        }

                    }).
                    catch((error) => {

                        console.log(error);

                    });

            },
            isUrl = function (i) {

                return i.includes("://");

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            close = function () {

                emitHideModal();

            };
        onMounted(async () => {

            wallet.value = await getAccount();

            const token = toRaw(sellTokens.value[sellToken.value]);

            if (sellToken.value)
              sellTokens.value[sellToken.value].balance = await getTokenBalance(token.address);

            watch(
                sellToken,
                async () => {

                    sellTokens.value[sellToken.value].balance = await getTokenBalance(token.address);
                    getSwap();

                }
            );

            watch(
                buyToken,
                () => {

                    getSwap();

                }
            );

            watch(
                sellAmount,
                () => {

                    getSwap();

                }
            );

        });
        return {"localize": translation.localize,
            serverConfig,
            wallet,
            getSwap,
            buyOrSell,
            changeToken,
            getAccount,
            getTokenBalance,
            openTokensModal,
            showTokensModal,
            showSuccessModal,
            isOnChain,
            onOffChain,
            setOnOffChain,
            isFiat,
            isMetal,
            buyToken,
            buyTokens,
            sellToken,
            sellTokens,
            receiveAmount,
            sellAmount,
            placeOrder,
            formatSellAmount,
            currency,
            isUrl,
            getRawIcon,
            web3,
            icon,
            close,
            swap,
            emitHideModal};

    }
};

</script>
<style scoped>
input .sellAmount {
  style: none;
}
.super-line {
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
}
.super-text {
  font-size: 0.8em;
  color: #efefef;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.subscript {
  color: #777;
  font-size: 0.7em;
  font-weight: bold;
  text-align: right;
}
.content-container {
  margin-top: 150px;
}
.modal-dialog {
  pointer-events: all;
}
.send-inactive {
  float: left;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #ff7dcb;
  color: #ff7dcb;
  font-size: 0.8em;
  font-weight: bold;
}
.send-active {
  float: left;
  cursor: pointer;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #e10087;
  background-color: #e10087;
  font-weight: bold;
  color: #fff;
  font-size: 0.8em;
}
.pay-in-outer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  border-radius: 15px;
}
.pay-in-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(45deg, #210322, #031529);
  margin: 5px;
  border-radius: 12px;
}
.pay-out {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  background: linear-gradient(45deg, #6a6a6a, #4a4a4a);
  border-radius: 15px;
}
.pay-amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}
.pay-in-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  text-align: right;
  margin-right: 30px;
}
.pay-out-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
}
.pay-amount-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: inherit;
  font-size: 1.44em;
  color: white;
  max-width: 240px;
  height: 30px;
  text-align: right;
  margin: 0px 20px;
  color: #efefef;
}
.reverse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #555;
  margin-bottom: 35px;
  position: relative;
}
.reverse-swap-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 20px;
  width: 32px;
  height: 32px;
  padding: 2px;
  color: #777;
  border: 1px solid #555;
  cursor: pointer;
  background-color: #333;
  transition: all 300ms ease-in-out;
}
.reverse-swap-icon:hover {
  background: radial-gradient(#4e2e4c, #393939);
  color: rgb(255, 124, 245);
}
.error {
  float: left;
  margin: 15px;
  color: grey;
}
.success {
  float: left;
  margin: 15px;
  color: #e10087;
}
.swap-text-box {
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #555;
}
.swap-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.swap-text {
  width: 100px;
  font-size: 1.2em;
  font-weight: bold;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#message {
  font-size: 0.8em;
  margin: 15px;
  transition: all 500ms ease-in-out;
}
.on-off-chain-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.on-off-chain-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.token-dropdown {
  color: #fff;
}
.symbol-text {
  color: #efefef;
  font-size: 0.85em;
  font-weight: bold;
}
.balance-text {
  color: #efefef;
  font-size: 0.85em;
  font-weight: bold;
  text-align: right;
}
.token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.margin-sell {
  margin: 10px;
  padding: 9px 12px;
}
.margin-buy {
  margin: 10px;
  padding: 10px;
}
.swap-token-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #444;
  border: 1px solid #444;
  border-radius: 9px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.swap-token-container:hover {
  border: 1px solid #46b6ff;
  box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
}
.rsi {
  padding-top: 5px;
}
textarea:focus, input:focus{
    outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.circ{
    opacity: 0;
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.tick{
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
    -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
    -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
    -o-transition: stroke-dashoffset 1s 0.5s ease-out;
    transition: stroke-dashoffset 1s 0.5s ease-out;
}
.success + svg .path{
    opacity: 1;
    stroke-dashoffset: 0;
}
</style>
