<template>
  <div ref="rootNode">
    <div ref="threeContainer"></div>
  </div>
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import * as THREE from 'three';

export default {
  name: 'DiamondShape',
  setup() {
    const rootNode = ref(),
    width = ref(),
    height = ref(),
    threeContainer = ref(null);
    let renderer, camera, metalMesh;

    const initScene = () => {

      width.value = rootNode.value.parentElement.clientWidth;
      height.value = rootNode.value.parentElement.clientHeight;

      // Set up scene
      const scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, width.value / height.value, 0.1, 1000);
      renderer = new THREE.WebGLRenderer( { alpha: true } );
      renderer.setSize(width.value, height.value);
      threeContainer.value.appendChild(renderer.domElement);

      const geometry = new THREE.BufferGeometry();

      const textureLoader = new THREE.TextureLoader();

      const loader = new GLTFLoader();

      Promise.all([
          loader.loadAsync('public/rareium.glb'),
          textureLoader.loadAsync('public/images/rareium.png'),
          textureLoader.loadAsync('public/images/rareium_normal_map.png'),
      ]).then(([gltf, texture, normalMap]) => {

          // enable repeat texture
          //texture.wrapS = THREE.RepeatWrapping;
          //texture.wrapT = THREE.RepeatWrapping;

          texture.minFilter = THREE.LinearFilter;
          texture.magFilter = THREE.LinearFilter;

          texture.flipY = true; 
          normalMap.flipY = false; 

          metalMesh = gltf.scene.children[0];
          metalMesh.castShadow = true; //default is false
          metalMesh.receiveShadow = false; //default

          scene.add(metalMesh);

          // set initial roation.
          metalMesh.rotation.x = THREE.MathUtils.degToRad(180);

/*
          const uvAttribute = metalMesh.geometry.getAttribute('uv');

          for (let i = 0; i < uvAttribute.count; i++) {

              uvAttribute.setX(i, 1 - uvAttribute.getX(i));

          }

          uvAttribute.needsUpdate = true;
*/

          const material = new THREE.MeshStandardMaterial({
              metalness: 0.8,
              roughness: 0.1,
              wireframe: false,
              transparent: true,
              opacity: 1,
              depthWrite: false,
              bumpMap: texture,
              map: texture,
              normalMap,
              bumpScale: 0.5
          });
          metalMesh.material = material;

          // Set Camera
          camera.position.set(0, 0, 20);

          // Lighting
          const ambientLight = new THREE.AmbientLight(0x404040, 0);
          scene.add(ambientLight);

          const directionalLight = new THREE.DirectionalLight(0xffffff, 20);
          directionalLight.position.set(0, 10, 20);
          scene.add(directionalLight);

          // Shadow

          const planeGeometry = new THREE.PlaneGeometry( 200, 200 );
          planeGeometry.rotateX( - Math.PI / 2 );

          const shadowMaterial = new THREE.ShadowMaterial();
          shadowMaterial.opacity = 0.5;

          const plane = new THREE.Mesh( planeGeometry, shadowMaterial );
          plane.position.y = -11;
          plane.receiveShadow = true;
          scene.add( plane );

          // runtime vars
          let initialRotation = 0;
          let time = 0;

          // simple effect
          const effect = function () {

              console.log('run effect')

              material.opacity = 0.5;
              material.color = 0xe385f7;

              setTimeout(()=> {

                  material.opacity = 1;
                  material.color = "";

              }, 10);

          }

          // Render loop
          const animate = function () {

              requestAnimationFrame(animate);

              metalMesh.rotation.y += 0.01;

              const verticalDelta = Math.sin(time) * 1;
              metalMesh.position.y = verticalDelta;

              const rotationDelta = Math.sin(initialRotation) * 0.1;
              metalMesh.rotation.x += THREE.MathUtils.degToRad(rotationDelta);

              initialRotation += 0.01;
              time += 0.01;

              if ((parseInt(time) % 2) === 0) {

                  // effect();

              }

              renderer.render(scene, camera);

          };

          animate();

      });

    };

    const handleResize = () => {

        // Update camera aspect ratio and renderer size on window resize
        camera.aspect = width.value / height.value;
        camera.updateProjectionMatrix();
        renderer.setSize(width.value, height.value);

    };

    onMounted(() => {

        initScene();
        window.addEventListener('resize', handleResize);

    });

    onBeforeUnmount(() => {

        // Remove event listener and clean up resources
        window.removeEventListener('resize', handleResize);
        renderer.dispose();

    });

    return {
      rootNode,
      width,
      height,
      threeContainer,
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

#threeContainer {
  width: 100%;
  height: 100vh; /* Adjust as needed */
}
</style>
