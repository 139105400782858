<template>
  <div
    ref="root"
    class="root"
  >
    <div class="app-background-b" />
    <div class="app-background-a bg2" />
    <div class="app-background-a bg3" />

    <div :class="pageContainer">
      <div :class="mainContainer">
        <div class="news-container">
          <div class="news-title">
            Expert Insights
          </div>
          <div class="news-link-container">
            <Transition name="slide-fade" :duration="{ enter: 1000, leave: 1000 }">
              <div
                :key="currentNews"
                @click.stop="openNews(currentNews)"
                v-if="currentNews < newsItems.length"
                class="news-link"
              >
                <div> {{ newsItems[currentNews].title }} </div>
                <div
                  class="flex-row"
                  v-if="newsItems[currentNews].creator"
                >
                  <div class="news-link-sub">
                    by {{newsItems[currentNews].creator}} - courtesy of 
                  </div>
                  <div
                    class="news-link-sub-link"
                    @click="gotoUrl('https://rawmaterials.net')"
                  >
                     rawmaterials.net
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <D3
          :class="d3GraphContainer"
          :data="chartData"
          :symbol="selectedSymbol"
          :tokens="liquidTokens"
          timeFrame="oneYear"
          multiGraph="true"
          @passback-symbols="updateSymbols"
          color="#0d233c"
        />
      </div>
      <div :class="tokensContainer">
        <div class="account-card-desktop">
          <div class="account-card-bg" />
          <div class="account-value-container">
            <div class="title">
              Balance
            </div>
            <div class="line" />
            <div class="main-balance">
              {{ cashBalance }}
            </div>
            <div class="balance-container">
              <div class="selected">
                USD
              </div>
              - 
              <div class="unselected">
                EUR
              </div>
            </div>
          </div>
        </div>

        <div class="small-space" />
        <div class="small-space" />
        <div class="small-space" />
        <div class="title">
          Portfolio
        </div>
        <div class="tokens-container-inner">
          <div class="small-space" />
          <div
            v-for="t in liquidTokens"
          >
            <div
              class="even-container"
              v-if="t.balance > 0"
              @click="getChartData(t.symbol)"
            >
              <div
                v-if="isEnabled(t.symbol)"
                :style="'background-color:' + t.color + '; min-width:7px; min-height:7px; border-radius: 3px;'"
              />
              <div
                v-else
                :style="'background-color:' + t.color + '; min-width:7px; min-height:7px; border-radius: 3px; opacity: 0;'"
              />
              <div class="token-icon">
                <div v-if="isUrl(t.icon) === true">
                  <img
                    class="token-img"
                    :src="t.icon"
                  >
                </div>
                <div v-else>
                  <div
                    class="token-icon"
                    v-html="getRawIcon(t.icon)"
                  />
                </div>
              </div>
              <div class="token-item-container">
                <div class="token-name">
                  {{ t.name }}
                </div>
                <div class="token-symbol">
                  {{ t.symbol }}
                </div>
              </div>
              <div class="token-item-container">
                <div class="token-balance">
                  {{ (t.balance/1000).toFixed(3) }} 
                </div>
                <div class="cash-balance">
                  <span>$ {{ (t.balance/1000 * t.price/100).toFixed(2) }}</span>
                </div>
              </div>
              <div
                class="buy-sell"
                @click.stop="openSwap(t.symbol)"
              >
                Buy / Sell
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lower-container-outer">
      <div
        class="buttons-container"
      >
        <div 
          @click="selectedTab = 'tokens'"
          class="overhead-button">
            Tokens
        </div>
        <div 
          @click="selectedTab = 'orders'"
          class="overhead-button">
            Orders
        </div>
        <div 
          @click="selectedTab == 'deposit'"
          class="overhead-button">
            Deposit
        </div>
        <div 
          @click="selectedTab == 'withdraw'"
          class="overhead-button">
            Withdraw
        </div>
        <div 
          @click="selectedTab == 'stake'"
          class="overhead-button">
            Stake
        </div>
      </div>
      <Transition
        name="slide-fade"
        :duration="{ enter: 500, leave: 500 }"
        class="lower-container-inner"
      >
        <div
          v-if="selectedTab == 'tokens'"
        >
          <div
            class="lower-even-container-title"
          >
            <div>
              Metal
            </div>
            <div>
              Balance
            </div>
            <div>
              Price
            </div>
            <div>
              Address
            </div>
            <div>
            </div>
          </div>
          <div
            v-for="t in liquidTokens"
            class="graph-marker"
          >
            <div
              class="lower-even-container"
              @click="getChartData(t.symbol)"
            >
              <div
                v-if="isEnabled(t.symbol)"
                :style="'border: 5px solid ' + t.color + '; width:14px; height:10px; border-radius: 50%;'"
              />
              <div
                v-else
                :style="'border: 5px solid ' + t.color + '; width:14px; height:10px; border-radius: 50%; opacity: 0;'"
              />
              <div class="token-icon">
                <div v-if="isUrl(t.icon) === true">
                  <img
                    class="token-img"
                    :src="t.icon"
                  >
                </div>
                <div v-else>
                  <div
                    class="token-icon"
                    v-html="getRawIcon(t.icon)"
                  />
                </div>
              </div>
              <div class="token-item-container">
                <div class="token-name">
                  {{ t.name }}
                </div>
                <div class="token-symbol">
                  {{ t.symbol }}
                </div>
              </div>
              <div class="token-item-container">
                <div class="token-balance">
                  {{ (t.balance/1000).toFixed(3) }} 
                </div>
                <div class="cash-balance">
                  <span>$ {{ (t.balance/1000 * t.price/100).toFixed(2) }}</span>
                </div>
              </div>
              <div class="token-item-container">
                $ {{ (t.price/100).toFixed(2) }}
              </div>
              <div class="token-item-container">
                {{ t.address }}
              </div>
              <div
                class="buy-sell"
                @click.stop="openSwap(t.symbol)"
              >
                Buy / Sell
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        class="lower-container-inner"
      >
        <div
          v-if="selectedTab == 'orders'"
        >
          <div
            class="lower-even-container-title"
          >
            <div>
              Sold
            </div>
            <div>
              Bought
            </div>
            <div>
              Price
            </div>
            <div>
              Order
            </div>
            <div>
              Trade
            </div>
            <div>
              Status
            </div>
            <div>
              Date
            </div>
          </div>
          <div
            class="order-item"
            v-for="(j,i) in orders"
          >
            <div class="lower-even-container">
              <div class="token-item-container">
                {{j.sell_token_symbol}} [ {{ j.sell_amount }} ]
              </div>
              <div class="token-item-container">
                {{j.buy_token_symbol}} [ {{ j.buy_amount }} ]
              </div>
              <div class="token-item-container">
                {{j.price}}
              </div>
              <div class="token-item-container">
                {{j.order_type}}
              </div>
              <div class="token-item-container">
                {{j.trade_type}}
              </div>
              <div class="token-item-container">
                {{j.status}}
              </div>
              <div class="token-item-container">
                {{j.created}}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <Transition>
      <CModal
        v-if="showSwapModal"
        color="#5d328d"
        width="medium"
        secondary-color="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        background-color="#333"
        @close="showSwapModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_SWAP") }} </div>
        </template>
        <template #body>
          <div class="swap-container">
            <Swap 
              :swap-token="swapToken"
              :swap-tokens="{...pseudoTokens,...liquidTokens}"
              @hide-modal-event="showSwapModal = false"
              @get-orders="getOrders()"
            />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

    <Transition>
      <CModal
        v-if="showNewsModal"
        width="large"
        color="#666"
        secondary-color="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showNewsModal = false"
      >
        <template #header>
          <div> {{ newsItems[selectedNews].title }} </div>
        </template>
        <template #body>
          <div
            class="news-modal-content"
            v-html="newsItems[selectedNews]['content:encoded']" 
          />
          <div
            @click="gotoUrl(newsItems[selectedNews].link)"
            class="rare-button"
          >
            Go To Full Article
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>
  </div>
</template>

<script>

import {iconStore} from "@/stores/icon";
import {emit, inject, onMounted, reactive, ref, toRefs, watch} from "vue";
import {equityDAOMarketplaceABI, equityDAONFTABI, equityDAOPlatformABI} from "../abi.js";
import {translationStore} from "@/stores/translation";
import {useRoute, useRouter} from "vue-router";
import Parser from 'rss-parser';
import Swap from "./Swap.vue";
import D3 from "./D3.vue";
import Web3 from "web3";
import Axios from "axios";

export default {
    "name": "TradeScreen",
    "components": {
        D3,
        Swap
    },
    "props": [
    ],
    "emits": [
    ],
    setup (props, {emit}) {

        const provider = ref(),

            web3 = inject("web3"),
            symbols = ref({}),
            serverConfig = toRefs(inject("serverConfig")),
            translation = translationStore(),
            icon = iconStore(),
            router = useRouter(),
            route = useRoute(),
            data = ref(),
            vw = ref(),
            vh = ref(),
            selectedTab = ref('tokens'),
            cashBalance = ref(0),
            showNewsModal = ref(false),
            showSwapModal = ref(false),
            liquidTokens = ref({}),
            pseudoTokens = ref({
                "USD":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "USD",
                    name: "United States Dollar",
                    balance: 0,
                    price: 1
                },
                "EUR":{
                    icon: "RAREIUM_RGA_ICON",
                    symbol: "EUR",
                    name: "Euro",
                    balance: 0,
                    price: 1
                }
            }),
            d3GraphContainer = ref(),
            pageContainer = ref(),
            mainContainer = ref(),
            tokensContainer = ref(),
            nfts = ref(),
            inventory = reactive([]),
            orders = ref([]),
            chartData = ref({}),
            selectedSymbol = ref(),
            swapToken = ref(),
            newsItems = ref([]),
            currentNews = ref(0),
            selectedNews = ref(),
            getUserBalance = function (s) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getUserBalance`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                    let r =  response.data;

                    cashBalance.value = 0;

                    var b;

                    try {

                        b = JSON.parse(r.token_balances);

                    } catch (e) {

                        console.log(e);
                        return;

                    }

                    for (var i in b) {

                        if (liquidTokens.value[i]) {

                            liquidTokens.value[i].balance = b[i];

                        }

                        // this is obviously wrong, just for testing
                        if (b[i])
                          cashBalance.value += b[i];

                    }
        
                    for (var i in r) {

                        let u = i.toUpperCase();

                        if (pseudoTokens.value[u]) {

                            pseudoTokens.value[u].balance = r[i];

                        }

                    }

                });

            },
            getOrders = function (s) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getOrders`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                    orders.value = response.data;

                })

            },
            isEnabled = function (s) {

                if (symbols.value[s] === true) {

                    return true;

                } else {

                    return false;

                }

            },
            updateSymbols = function (e) {

                symbols.value = e;

            },
            gotoUrl = function (url) {

                window.open(url);

            },
            openNews = function (id) {

              selectedNews.value = id;
              showNewsModal.value = true;

            },
            getNews = async function (t) {

                newsItems.value.splice(0,newsItems.value.length);

                const parser = new Parser(),
                feed = await parser.parseURL('https://corsproxy.io/?https%3A%2F%2Frawmaterials.net%2Ffeed%2F%3Ftag%3Dpartner%26show_images%3Dtrue');
                newsItems.value = feed.items;

/*
                feed.items.forEach(item => {
                    console.log(item)
                });
*/

            },
            openSwap = function (t) {

                swapToken.value = t;
                showSwapModal.value = true;

            },
            isUrl = function (i) {

                return i.includes("://");

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            getAccount = async function () {

                const acc = await web3.value.eth.getAccounts();

                if (acc.length > 0) {

                    return acc[0];

                } else if (window.ethereum) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"});
                    return accounts[0];

                }

                console.log("window.location = 'https://metamask.io'");
                return null;

            },
            getTokenURI = async function (address, token_id, owner, nft_address, id, callback) {

                const amc = new web3.value.eth.Contract(
                    equityDAONFTABI,
                    address
                );
                amc.methods.tokenURI(token_id).
                    call().
                    then((jsonRpcResult) => {

                        callback(
                            token_id,
                            owner,
                            jsonRpcResult,
                            nft_address,
                            id
                        );

                    });

            },
            getChartData = async function (symbol) {

                console.log(`click ${  symbol}`)

                selectedSymbol.value = 'react';

                let url;

                if (symbol === "RDY") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=dysprosiumoxid';

                } else if (symbol === "RPR") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=praseodymoxid';

                } else if (symbol === "RTB") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=terbiumoxid';

                } else if (symbol === "RGA") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=gallium';

                } else if (symbol === "RGE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=germanium';

                } else if (symbol === "RHF") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=hafnium';

                } else if (symbol === "RIN") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=indium';

                } else if (symbol === "RRE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=rhenium';

                } else if (symbol === "RND") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=neodymoxid';

                } else {

                    return;

                }

                Axios.get(
                    url,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        chartData.value[symbol] = processTradiumPrices(response.data, liquidTokens.value[symbol].price/100, liquidTokens.value[symbol].price/100);
                        selectedSymbol.value = symbol;

                });

            },
            processTradiumPrices = function(data, todayPriceUsd, todayPriceEur) {

                // for efficiency we assume object order is ok (sorting by data in browser on big data does not work well)
                const dataArray = Object.entries(data).map(([date, price]) => ({
                    date,
                    price
                }));

                if (dataArray.length === 0) {

                    return;

                }

                /*
                 * tradium provide prices on relative percent change
                 * first we need to calculate the price at zero relative to todays price.
                 * we can then base all chart data on this price relation
                 */

                const pUsd = dataArray[dataArray.length-1].price.usd,
                  pEur = dataArray[dataArray.length-1].price.eur,
                  usdBasePrice = todayPriceUsd / (1 + (parseInt(pUsd)/100)),
                  eurBasePrice = todayPriceEur / (1 + (parseInt(pEur)/100)),
                  absolutePrices = { usd: {}, eur: {} };

                for (const d of Object.keys(data)) {

                    const v = data[d],
                      usdPrice = usdBasePrice + usdBasePrice * (parseInt(v.usd)/100),
                      eurPrice = eurBasePrice + eurBasePrice * (parseInt(v.eur)/100);

                    // Save the absolute prices for the current date
                    absolutePrices.usd[d.split('.').reverse().join('-')] = usdPrice.toFixed(2);
                    absolutePrices.eur[d.split('.').reverse().join('-')] = usdPrice.toFixed(2);

                }

                return absolutePrices;

            },
            updateView = function () {

                if (serverConfig.view.value === "desktop") {

                    d3GraphContainer.value = 'd3-graph-desktop';
                    pageContainer.value = 'graph-container-desktop';
                    mainContainer.value = 'main-container-desktop';
                    tokensContainer.value = 'tokens-container-desktop';

                } else if (serverConfig.view.value === "laptop") {

                    d3GraphContainer.value = 'd3-graph-desktop';
                    pageContainer.value = 'graph-container-desktop';
                    mainContainer.value = 'main-container-desktop';
                    tokensContainer.value = 'tokens-container-desktop';

                } else if (serverConfig.view.value === "tablet") {

                    d3GraphContainer.value = 'd3-graph-desktop';
                    pageContainer.value = 'graph-container-desktop';
                    mainContainer.value = 'main-container-desktop';
                    tokensContainer.value = 'tokens-container-desktop';

                } else if (serverConfig.view.value === "mobile") {

                    d3GraphContainer.value = 'd3-graph-mobile';
                    pageContainer.value = 'graph-container-mobile';
                    mainContainer.value = 'main-container-mobile';
                    tokensContainer.value = 'tokens-container-mobile';

                } else {

                    pageContainer.value = 'graph-container-mobile';
                    mainContainer.value = 'main-container-mobile';
                    tokensContainer.value = 'tokens-container-mobile';

                }

            },
            getAllERC20Tokens = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getAllERC20Tokens`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        for (let i = 0; i < response.data.length; i++) {

                            const tid = response.data[i].symbol;

                            if (tid) {

                                liquidTokens.value[tid] = response.data[i];
                                liquidTokens.value[tid].balance = 0;

                            }

                        }
                        getUserBalance();

                  });

            },
            getTokensByOwnerAddress = async function (address) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getTokensByOwnerAddress`,
                    {

                        "params": {

                            address

                        }

                    }
                ).
                    then((response) => {

                        inventory.splice(0);

                        for (let i = 0; i < response.data.length; i++) {

                            inventory.push(response.data[i]);

                            getTokenURI(
                                response.data[i].nft_address,
                                response.data[i].token_id,
                                response.data[i].owner,
                                response.data[i].nft_address,
                                response.data[i].id,
                                (token_id, owner, uri, nft_address, id) => {

                                    uri = uri.replace(
                                        "ipfs://",
                                        "https://w3s.link/ipfs/"
                                    );
                                    Axios.get(
                                        uri,
                                        {

                                            "params": {

                                            }

                                        }
                                    ).
                                        then((tokenData) => {

                                            tokenData.data.nft_address = nft_address;
                                            tokenData.data.token_id = token_id;
                                            tokenData.data.owner = owner;
                                            tokenData.data.id = id;
             //                               inventory.push(tokenData.data);

                                        }).
                                        catch((error) => {

                                            throw error;

                                        });

                                }
                            );

                        }

                    });

            };

        onMounted(async () => {

            getNews();
            getUserBalance();

/*
 *            const address = await getAccount();
 *            getTokensByOwnerAddress(address);
 */
            getAllERC20Tokens();

            vw.value = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            vh.value = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

            updateView();
            getOrders();

            (function loopNews (i) {

              setTimeout(function () {

                currentNews.value = i

                if (i === newsItems.value.length-1) {

                    i=0;

                } else {

                    i++;

                }

                loopNews(i);

              }, 10000);

            })(0);

            watch(
                serverConfig.chainId,
                () => {

                    // ChangeChain(serverConfig.chainId.value);

                }
            );

            watch(
                serverConfig.view,
                () => {

                    updateView();

                }
            );

        });
        return {"localize": translation.localize,
            serverConfig,
            translation,
            isEnabled,
            inventory,
            orders,
            symbols,
            data,
            vh,
            vw,
            icon,
            selectedTab,
            updateSymbols,
            liquidTokens,
            pseudoTokens,
            router,
            route,
            chartData,
            gotoUrl,
            getNews,
            openNews,
            getOrders,
            newsItems,
            currentNews,
            selectedNews,
            d3GraphContainer,
            pageContainer,
            mainContainer,
            tokensContainer,
            selectedSymbol,
            swapToken,
            openSwap,
            showSwapModal,
            showNewsModal,
            getChartData,
            getRawIcon,
            getAccount,
            getTokenURI,
            getTokensByOwnerAddress,
            cashBalance,
            processTradiumPrices,
            isUrl,
            updateView,
            web3};

    }
};

</script>
<style scoped>
.graph-marker {
    display: flex;
    align-items: center;
    padding: 10px;
}
.root {
    min-height: 100vh;
}
.small-space {
    height: 20px;
}
.top-space {
    height: 100px;
}
.main-container-desktop {
    width: 70%;
}
.main-container-mobile {
    width: 100%;
}
.graph-container-desktop {
    display: flex;
    flex-direction: row;
    margin: 20px;
    align-items: flex-start;
}
.graph-container-mobile {
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: flex-start;
}
.tokens-container-desktop {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px 20px 20px;
    width: 30%;
}
.tokens-container-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px 20px 20px;
    width: 100%;
}
.tokens-container-inner {
    width: 100%;
    height: 100%;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.main-chart {
    display: flex;
    flex-grow: 1;
}
.d3-graph-desktop {
    height: 70vh;
    height: calc(100vh - 350px);
    width: 100%;
    margin-left: -20px;
}
.d3-graph-mobile {
    height: 70vh;
    height: calc(100vh - 200px);
    width: 100%;
    margin-left: -20px;
}
.app-background-a {
    animation:slide 30s ease-in-out infinite alternate;
    background-image: linear-gradient(45deg,#fff 30%,#00000000 70%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:relative;
    right:-50%;
    top:0;
    z-index:-1;
}
.app-background-b {
    width: 100%;
    height: 100%;
    overflow: auto;
    animation: gradient 600s ease infinite alternate;
    background-size: 400% 400%;
    position: fixed;
    z-index: -1;
}
/*
  background: radial-gradient(ellipse at 50% 100%,#0a3148,#030a29);
  background: radial-gradient(ellipse at 0 100%,#030e25,#14dce5);
  background: radial-gradient(ellipse at 0% 100%, #030d25, #191602, #01110d);
  background-image: radial-gradient(ellipse at 50% 100%,#d30000,#f5d406);
*/
.bg2 {
    animation-direction:alternate-reverse;
    animation-duration:40s;
}
.bg3 {
    animation-duration:50s;
}
.title {
    display: flex;
    justify-content: center;
    margin: 10px;
    color: #0d233c;
    font-size: 0.8em;
    font-weight: bold;
    text-shadow: 0px 0px 10px white;
}
.even-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    padding: 5px 10px;
    scrollbar-y: auto;
    cursor: pointer;
}
.even-container:hover {
    background: linear-gradient(45deg,#cbcbcb80,transparent);
    cursor: pointer;
    border-radius: 30px;
}
.lower-even-container-title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    padding: 5px 10px;
}
.lower-even-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 50px;
    padding: 5px 10px;
    scrollbar-y: auto;
    cursor: pointer;
}
.lower-even-container:hover {
    background: linear-gradient(45deg,#cbcbcb80,transparent);
    cursor: pointer;
}
img.token-img {
    height: 42px;
    width: 42px;
}
.token-icon {
    display: flex;
    height: 48px;
    width: 48px;
    color: #fff;
    margin: 0px 10px;
    justify-content: center;
}
.token-name {
    color: #0d233c;
    font-weight: bold;
    font-size: 0.8em;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.token-symbol {
    color: #ededed;
    font-weight: bold;
    font-size: 0.8em;
    color: #0d233c;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    transition: all 300ms ease-in-out;
}
.token-item-container {
    display: flex;
    width: 30%;
    flex-direction: column;
}
.token-balance {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.8em;
    color: #0d233c;
}
.cash-balance {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-right: 5px;
    color: #0d233c;
    font-weight: bold;
    font-size: 0.8em;
}
.buy-sell {
    color: white;
    font-size: 0.85em;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    width: 70px;
    text-wrap: nowrap;
    background-image: linear-gradient(338deg,#ff7cf5,#46b6ff);
    transition: all 300ms ease-in-out;
}
.buy-sell:hover {
    color: white;
    box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
    background-image: linear-gradient(338deg,#ff3ff0,#1ea6ff);
}
.swap-even-container:hover {
}
.account-card-desktop {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
    border-radius: 40px;
    box-shadow: 16px 16px 64px rgba(49,89,211,.26);
    background: #fff;
}
.account-value-container {
  width: 100%;
  padding: 20px;
}
.account-card-bg {
    margin-top: 20px;
    position: absolute;
    width: 90%;
    height: 100%;
    border-radius: 40px;
    background-color: hsla(0,0%,100%,.6);
    box-shadow: 16px 16px 64px rgba(49,89,211,.16);
    z-index: -1;
}
/*
    transform: rotate3d(1.1, 1.1, 1.1, 35deg) translate(-100px, 10px);
*/
.sub-header {
    text-align: center;
    font-weight: 700;
    line-height: 35px;
    color: #22255c;
    font-size: .9em;
}
.line {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    border-bottom: 1px solid #000;
}
.main-balance {
    color: #0d233c;
    font-size: calc(1vw + 1vh);
    text-align: center;
}
.selected {
    font-size: .6em;
    font-weight: 700;
    margin: 5px;
}
.suselected {
    font-size: .6em;
    font-weight: 400;
    cursor: pointer;
    margin: 5px;
}
.balance-container {
    display: flex;
    justify-content: center;
}
.flex-grow {
    display: flex;
    flex-grow: 1;    
}
.news-title {
    margin-left: 40px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.7em;
}
.news-container {
    margin: 20px;
}
.news-link-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 50px;
    padding: 20px;
    text-indent: 20px;
    align-items: center;
    border-radius: 40px;
}
.news-link {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #0d233c;
    font-size: 0.9em;
    font-weight: bold;
}
.news-link:hover {
    text-decoration: underline;
    cursor: pointer;
} 
.news-link-sub {
    color: #0d233c;
    font-size: 0.8em;
}
.news-link-sub-link {
    color: #0d233c;
    font-size: 0.8em;
}
.news-link-sub-link {
    color: #0d233c;
    font-size: 0.8em;
}
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
.link {
    color:#ff7cf5;
}
.link:hover {
    color:#46b6ff;
}
.news-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.rare-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.lower-container-outer {
    width: 70%;
    position: relative;
    margin-top: 200px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    border-radius: 40px;
    box-shadow: 16px 16px 64px rgba(49,89,211,.26);
    z-index: 1;
}
.lower-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    width: 100%;
    min-height: 100px;
    border-radius: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.buttons-container {
    display: flex;
    justify-content: space-around;
    position: absolute;
    margin-top: -50px;
    width: 100%;
    z-index: -1;
}
.overhead-button {
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
    color: #0d233c;
    margin: 5px;
    padding: 20px 30px 30px 30px;
    border-radius: 20px;
    box-shadow: 16px 16px 64px rgba(49,89,211,.26);
    background: white;
}
.order-item {
    display: flex;
    flex-direction: row;
    text-align: center;
}
.account-value-container {
  width: 100%;
}
.item-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}
@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}
@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}
</style>
