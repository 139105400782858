<template>
  <div ref="rootNode">
    <div ref="threeContainer"></div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import * as THREE from 'three';
import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

export default {
  name: 'PieChart',
  components: {
  },
  setup() {
    const rootNode = ref(),
    width = ref(),
    height = ref(),
    radius = 10,
    chartHeight = 0.4,
    radialSegments = 64,
    threeContainer = ref(null),
    scene = new THREE.Scene(),
    labelRenderer = new CSS2DRenderer(),
    pie = new THREE.Group();

    let renderer, camera;

    const initScene = () => {

      width.value = rootNode.value.parentElement.clientWidth;
      height.value = rootNode.value.parentElement.clientHeight;

      // Set up scene
      camera = new THREE.PerspectiveCamera(75, width.value / height.value, 1, 1000);
      renderer = new THREE.WebGLRenderer( { alpha: true, antialias: true } );
      renderer.setSize(width.value, height.value);
      threeContainer.value.appendChild(renderer.domElement);

      // Lighting
      const ambientLight = new THREE.AmbientLight(0x1f1f1f, 3);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 10);
      directionalLight.position.set(0, 10, 20);
      scene.add(directionalLight);

      // set pie position
      pie.position.set(0,0,0);

      scene.add(pie);

      const angles = [
          { start: 0, 
            end: Math.PI / 2,
            color: '#46b6ff',
            position : new THREE.Vector3(0,0,0),
            text: "VC" },
          { start: Math.PI / 2,
            end: Math.PI,
            color: '#ff7cf5',
            position : new THREE.Vector3(0,0,0),
            text: "Metals" },
          { start: Math.PI,
            end: 1.5 * Math.PI,
            color: '#830dc6',
            position : new THREE.Vector3(0,0,0),
            text: "Operations" },
          { start: 1.5 * Math.PI,
            end: 2 * Math.PI,
            color: '#f6cc01',
            position: new THREE.Vector3(0,0,0),
            text: "Liquidity Pool" } 
      ];

      // Create and add the 4 pie slices to the scene
      angles.forEach(angle => {

          const slice = createSlice(angle.start, angle.end, angle.color);

          const textDivADiv = document.createElement( 'div' );
          textDivADiv.className = "label";
          textDivADiv.textContent = angle.text;
          textDivADiv.style.backgroundColor = 'transparent';

          const textDivALabel = new CSS2DObject( textDivADiv );
          textDivALabel.center.set(0,0);
          textDivALabel.position.set(0, 0, 0);

          slice.add(textDivALabel);
          slice.position.copy(new THREE.Vector3(0,0,0));
          pie.add(slice);

      });
      console.log(pie);

      // labels
      labelRenderer.setSize(width.value, height.value);
      labelRenderer.domElement.style.position = 'absolute';
      // labelRenderer.domElement.style.top = '0px';
      threeContainer.value.appendChild(labelRenderer.domElement);

      // Set camera position
      camera.position.set(0, 6, 15);

      animate();

    };

    const createSlice = (startAngle, endAngle, color) => {

        const shape = new THREE.Shape();

        const numPoints = 50;
        const angleStep = (endAngle - startAngle) / numPoints;

        // Move to the start of the outer arc
        shape.moveTo(radius * Math.cos(startAngle), radius * Math.sin(startAngle));

        // Define the outer arc
        for (let i = 0; i <= numPoints; i++) {

            const angle = startAngle + i * angleStep;
            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle);
            shape.lineTo(x, y);

        }

        const innerRadius = radius * 0.4; // Adjust this value to control the size of the cutout
        shape.lineTo(innerRadius * Math.cos(endAngle), innerRadius * Math.sin(endAngle)); // Move to the end of the inner arc

        // Define the inner arc in reverse
        for (let i = numPoints; i >= 0; i--) {

            const angle = startAngle + i * angleStep;
            const x = innerRadius * Math.cos(angle);
            const y = innerRadius * Math.sin(angle);
            shape.lineTo(x, y);

        }

        shape.closePath();

        const extrudeSettings = {
            depth: chartHeight,
            bevelEnabled: false,
        };

        const extrudeGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        const extrudeMaterial = new THREE.MeshStandardMaterial({ color: color, side: THREE.DoubleSide, roughness: 0.5, metalness: 0.5 });
        const extrudedSlice = new THREE.Mesh(extrudeGeometry, extrudeMaterial);

        // Position the slice correctly
        extrudedSlice.rotation.x = Math.PI / 2;
        extrudedSlice.position.z = -height / 2;

        return extrudedSlice

    };

    const animate = () => {

        requestAnimationFrame(animate);

        pie.rotation.y += 0.002;

        renderer.render(scene, camera);
        labelRenderer.render( scene, camera );

    };

    const handleResize = () => {

        // Update camera aspect ratio and renderer size on window resize
        camera.aspect = width.value / height.value;
        camera.updateProjectionMatrix();
        renderer.setSize(width.value, height.value);
        labelRenderer.setSize(width.value, height.value);

    };

    onMounted(() => {

        initScene();

        camera.lookAt(pie.position);
        window.addEventListener('resize', handleResize);

    });

    onBeforeUnmount(() => {

        // Remove event listener and clean up resources
        window.removeEventListener('resize', handleResize);
        renderer.dispose();

    });

    return {
      rootNode,
      width,
      height,
      threeContainer,
      radius,
      chartHeight,
      radialSegments,
      createSlice,
      pie,
      animate,
      scene,
      labelRenderer
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

#threeContainer {
  width: 100%;
  height: 100vh; /* Adjust as needed */
}
/*
* I can't get this to work so hiding for now
*/
.label {
  display: none;
  color: black;
  font-family: Arial, sans-serif;
  font-size: 16px;
  background-color: transparent; /* Ensure transparency */
  padding: 2px;
}
</style>
