<template>
  <div>
    <Dashboard>
      <div class="token-container">
        <div class="create-token-header">
          <h2 class="createtitletext">
            {{ localize('TEXT_CREATE_CRYPTO_TOKEN') }}
          </h2>
        </div>

        <div class="create-sub-header">
          Create ERC20 Token (normal crypto token)
        </div>
        <div class="step-1-container">
          <div class="create-token-container">
            <CInput
              class="margin-top"
              :value="token.name"
              :title="localize('TEXT_TOKEN_NAME')"
              :placeholder="localize('TEXT_TOKEN_NAME_PLACEHOLDER')"
              @inputValue="token.name = $event"
            />
            <CInput
              class="margin-top"
              :value="token.symbol"
              :title="localize('TEXT_TOKEN_SYMBOL')"
              :placeholder="localize('TEXT_TOKEN_SYMBOL_PLACEHOLDER')"
              @inputValue="token.symbol = $event"
            />
            <CInput
              class="margin-top"
              :value="token.initialSupply"
              :title="localize('TEXT_TOKEN_SUPPLY')"
              :placeholder="0"
              @inputValue="token.initialSupply = $event"
            />
            <CInput
              class="margin-top"
              :value="token.maxSupply"
              :title="localize('TEXT_MAX_SUPPLY')"
              :placeholder="localize('TEXT_TOKEN_SUPPLYPLACEHOLDER')"
              @inputValue="token.maxSupply = $event"
            />
          </div>
        </div>

        <div
          class="create-save-token-button"
          @click="createToken"
        >
          {{ localize('TEXT_CREATE_TOKEN') }}
        </div>

        <div class="create-sub-header">
          Create ERC20 RWA (Real World Asset) Token
        </div>
        <div>
          These tokens have in built custom demurrage fees and daily mint limits.
        </div>
        <div class="step-1-container">
          <div class="create-token-container">
            <CInput
              class="margin-top"
              :value="rwatoken.name"
              :title="localize('TEXT_TOKEN_NAME')"
              :placeholder="localize('TEXT_TOKEN_NAME_PLACEHOLDER')"
              @inputValue="rwatoken.name = $event"
            />
            <CInput
              class="margin-top"
              :value="rwatoken.symbol"
              :title="localize('TEXT_TOKEN_SYMBOL')"
              :placeholder="localize('TEXT_TOKEN_SYMBOL_PLACEHOLDER')"
              @inputValue="rwatoken.symbol = $event"
            />
            <CInput
              class="margin-top"
              :value="rwatoken.initialSupply"
              :title="localize('TEXT_TOKEN_SUPPLY')"
              :placeholder="localize('TEXT_TOKEN_SUPPLYPLACEHOLDER')"
              @inputValue="rwatoken.initialSupply = $event"
            />
            <CInput
              class="margin-top"
              :value="rwatoken.maxSupply"
              :title="localize('TEXT_MAX_SUPPLY')"
              :placeholder="0"
              @inputValue="rwatoken.maxSupply = $event"
            />
          </div>
        </div>

        <div
          class="create-save-token-button"
          @click="createRWAToken"
        >
          {{ localize('TEXT_CREATE_TOKEN') }}
        </div>

        <div class="create-sub-header">
          Transfer Token
        </div>
        <div>
          Tokens are minted to the minting contract by default, this function allows you to send them to another address.
        </div>
        <div>
          <div class="create-token-container">
            <CInput
              class="margin-top"
              :value="sendToken.to"
              :title="localize('TEXT_TOKEN_TO')"
              :placeholder="localize('TEXT_TOKEN_TO_PLACEHOLDER')"
              @inputValue="sendToken.to = $event"
            />
            <CInput
              class="margin-top"
              :value="sendToken.token"
              :title="localize('TEXT_TOKEN_ADDRESS')"
              :placeholder="localize('TEXT_TOKEN_ADDRESS_PLACEHOLDER')"
              @inputValue="sendToken.token = $event"
            />
            <CInput
              class="margin-top"
              :value="sendToken.amount"
              :title="localize('TEXT_TOKEN_AMOUNT')"
              :placeholder="localize('TEXT_TOKEN_AMOUNT_PLACEHOLDER')"
              @inputValue="sendToken.amount = $event"
            />
            <CDropdown
              class="margin-top"
              title="Factory"
              toggler-text="RWA"
            >
              <CDropdownItem
                v-for="(f,i) in ['Regular','RWA']"
                :key="i"
                @click.native="tokenType = f"
              >
                {{ localize(f) }}
              </CDropdownItem>
            </CDropdown>
          </div>
          <div
            class="create-save-token-button"
            @click="sendTokens"
          >
            {{ localize('TEXT_SEND_TOKEN') }}
          </div>
        </div>

        <CModal
          v-if="showTokenModal"
          color="#f9b115"
          title="Please Wait"
          @close="showTokenModal = false"
        >
          <template #header>
            <div> Begin Create </div>
          </template>
          <template #body>
            <div>
              Begin Create
            </div>
          </template>
          <template #footer>
            <div class="hidden" />
          </template>
        </CModal>
      </div>
    </Dashboard>
  </div>
</template>
<script>

/*
  iRwaMintableABI,
  iRwaTokenABI,
  raredexMintABI
*/

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import Axios from "axios";
import FormData from "form-data";
import {erc20ABI,
  erc20FactoryABI,
  rwaTokenABI,
  rwaTokenFactoryABI,
  iRwaMintableABI,
  iRwaTokenABI,
  raredexMintABI} from "../abi.js";

export default {
    "name": "CreateERC20",
    "components": {
    },
    "props": {
        "mintAddress": {
            type: String,
            default: ""
        },
        "tokenFactoryAddress": {
            type: String,
            default: ""
        },
        "rwaTokenFactoryAddress": {
            type: String,
            default: ""
        },
    },
    setup (props, {emit}) {

        const translation = translationStore(),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            icon = iconStore(),
            web3 = inject("web3"),
            hasCreateTokenSub = false,
            contracts = ref([]),
            tokenType = ref('Regular'),
            token = ref({
                "id": 0,
                "name": "",
                "symbol": "",
                "description": "",
                "initialSupply": "",
                "maxSupply": "",
                "chain_id": ""
            }),
            rwatoken = ref({
                "id": 0,
                "name": "",
                "symbol": "",
                "description": "",
                "initialSupply": "",
                "maxSupply": "",
                "chain_id": ""
            }),
            tokens = ref([]),
            sendToken = ref({
                "to": "",
                "token": "",
                "amount": ""
            }),
            showTokenModal = ref(false),
            getAccount = async function () {

                const acc = await web3.value.eth.getAccounts();

                if (acc.length > 0) {

                    return acc[0];

                } else if (window.ethereum) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"});
                    return accounts[0];

                }

                console.log("window.open(https://metamask.io");
                window.open("https://metamask.io");


            },
            fetchMyTokens = async function () {

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0],
                    amc = new web3.value.eth.Contract(
                        erc20FactoryABI,
                        props.tokenFactoryAddress
                    );

                amc.methods.contracts(
                    addr,
                    0
                ).call((e, r) => {

                    contracts.value.splice(0);
                    contracts.value.push(r);

                });

            },
            // create token on-chain
            createToken = async function () {

                if (!(token.value.name && token.value.symbol && token.value.initialSupply)) {

                    console.log("bad token");
                    return;

                }

                showTokenModal.value = true;

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0],
                    amc = new web3.value.eth.Contract(
                        erc20FactoryABI,
                        props.tokenFactoryAddress
                    );

                let gasLimit = await amc.methods.create(
                    token.value.name,
                    token.value.symbol,
                    token.value.initialSupply,
                    token.value.maxSupply,
                    addr
                ).estimateGas(
                    {
                        "from": addr
                    });

                gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                amc.methods.create(
                    token.value.name,
                    token.value.symbol,
                    token.value.initialSupply,
                    token.value.maxSupply,
                    addr
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((r) => {

                        console.log(r);
                        if (r) {

                            showTokenModal.value = false;
                            window.open("https://arbiscan.io/tx/" + r.transactionHash, "_blank");

                        } else {

                            alert("There was an error creating Token, please contact support@compdeep.com");

                        }

                    });

            },
            createRWAToken = async function () {

                if (!(rwatoken.value.name && rwatoken.value.symbol && rwatoken.value.initialSupply)) {

                    console.log("bad token");
                    return;

                }

                showTokenModal.value = true;

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0],
                    amc = new web3.value.eth.Contract(
                        rwaTokenFactoryABI,
                        props.rwaTokenFactoryAddress
                    );

                let gasLimit = await amc.methods.create(
                    rwatoken.value.name,
                    rwatoken.value.symbol,
                    rwatoken.value.initialSupply,
                    rwatoken.value.maxSupply,
                    addr
                ).estimateGas(
                    {
                        "from": addr
                    });

                gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                amc.methods.create(
                    rwatoken.value.name,
                    rwatoken.value.symbol,
                    rwatoken.value.initialSupply,
                    rwatoken.value.maxSupply,
                    addr
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((r) => {

                        console.log(r);
                        if (r) {

                            showTokenModal.value = false;
                            window.open("https://arbiscan.io/tx/" + r.transactionHash, "_blank");

                        } else {

                            alert("There was an error creating Token, please contact support@compdeep.com");

                        }

                    });

            },
            sendTokens = async function () {

                if (!(sendToken.value.to && sendToken.value.token && sendToken.value.amount)) {

                    console.log("bad token");
                    // showCreateTokenErrorModal.value = true;
                    return;

                }

                showTokenModal.value = true;
                const amount = web3.value.utils.toWei(sendToken.value.amount),

                    accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0];

                var amc;
                if (tokenType.value === "Regular") {
                    amc = new web3.value.eth.Contract(
                        erc20FactoryABI,
                        props.tokenFactoryAddress
                    );
                } else if (tokenType.value === "RWA") {
                    amc = new web3.value.eth.Contract(
                        erc20FactoryABI,
                        props.rwaTokenFactoryAddress
                    );
                } else {
                    alert('choose token type')
                    return;
                }

                let gasLimit = await amc.methods.sendTokens(
                    sendToken.value.to,
                    sendToken.value.token,
                    amount
                ).estimateGas(
                    {
                        "from": addr
                    },
                    (error, estimatedGas) => {

                        console.log("estimatedGas");
                        console.log(estimatedGas);

                    }
                );

                gasLimit = gasLimit <= 50000n ? gasLimit * 3n : gasLimit * 12n / 10n;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                amc.methods.sendTokens(
                    sendToken.value.to,
                    sendToken.value.token,
                    amount
                ).send({
                    "from": addr,
                    gasPrice,
                    gasLimit
                }).
                    then((e, r) => {

                        console.log(r);
                        if (r) {

                            showTokenModal.value = false;

                        } else {

                            alert("There was an error creating Token, please contact support@compdeep.com");

                        }

                    });

            },
            subscribeToNewTokens = async function () {

                // web3.eth.abi.encodeEventSignature('createNFTEvent')
                console.log("subscribeToNewTokens");

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0],

                    options = {
                    },

                    subCreateToken = web3.value.eth.subscribe(
                        "logs",
                        options
                    );

                subCreateToken.on(
                    "error",
                    (err) => {

                        throw err;

                    }
                ).
                    on(
                        "connected",
                        (subscriptionId) => {

                            console.log(
                                "new tokens SubID: ",
                                subscriptionId
                            );

                        }
                    ).
                    on(
                        "data",
                        (event) => {

                            const t = web3.value.eth.abi.decodeLog(
                                [
                                    {"indexed": true,
                                        "internalType": "address",
                                        "name": "nft",
                                        "type": "address"},
                                    {"indexed": false,
                                        "internalType": "string",
                                        "name": "name",
                                        "type": "string"},
                                    {"indexed": false,
                                        "internalType": "string",
                                        "name": "symbol",
                                        "type": "string"},
                                    {"indexed": true,
                                        "internalType": "address",
                                        "name": "owner",
                                        "type": "address"}
                                ],
                                event.data,
                                [
                                    event.topics[1],
                                    event.topics[2]
                                ]
                            );

                            if (web3.value.utils.toChecksumAddress(t.owner) == web3.value.utils.toChecksumAddress(addr) && t.symbol == token.value.vue.symbol && !route.params.address) {

                                showTokenModal.value = false;

                            }

                        }
                    );

            };

        onMounted(() => {

            if (hasCreateTokenSub.value === false) {

                subscribeToNewTokens();
                hasCreateTokenSub.value = true;

            }

            watch(
                serverConfig.chainId,
                () => {

                    token.value.chain_id = serverConfig.chainId.value;

                    setTimeout(()=>{

                        fetchMyTokens();

                    },300);

                }
            );

        });

        return {"localize": translation.localize,
            contracts,
            createToken,
            createRWAToken,
            sendTokens,
            sendToken,
            rwatoken,
            tokenType,
            token,
            tokens,
            icon,
            web3,
            showTokenModal,
            getAccount,
            serverConfig,
            subscribeToNewTokens,
            hasCreateTokenSub,
            showTokenModal,
            fetchMyTokens,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.create-token-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.container {
  margin-top: -100px;
  text-align: left;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.token-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.create-upload-box {
  height: 150px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  opacity: 1.0;
  cursor: pointer;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-token-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-token-preview-bg:hover {
  opacity: 0.5;
}
.create-token-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.token-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-details {
  margin-top: 20px;
}
.create-targeting {
  margin-top: 20px;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-token-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-token-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-token-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
</style>
